import React from 'react';
import Blog from "../Components/Blogs/Blog";
import Navbar from "../Comman/Header/Navbar";
import Footer from '../Comman/Footers/Footer';


const Blog1 = () => {
  return (
   <>
   <Navbar/>
   <Blog/>
   <Footer/>
   </>
  );
};



export default Blog1;
