import React from "react";
import Navbar from "../Comman/Header/Navbar";
import Footer from "../Comman/Footers/Footer";
import { Container, Row, Col } from "react-bootstrap";

const Terms = () => {
  return (
    <>
      <Navbar />

      <div className="privacy-section pt-120 pb-120">
        <Container>
          <Row>
            <Col md={12}>
              <h4 className="display-four text-center mt-5 mb-4 tcp-1">
                Terms and Conditions
              </h4>
              <h5>Contract</h5>
              <p>
                The clients approval for work to commence shall be deemed a
                contractual agreement between the client and Impetrosys IT
                Solutions. The approval for the work can be through either an
                email confirming back the quote (with the quote document
                attached) or the quote document signed by the client. Important:
                Payment of the advance fee indicates that the client accepts
                these terms and conditions, and approves to commence the work.
              </p>
              <h5>1 Year Free Technical Support:</h5>
              <p>
                Impetrosys provides 1 year free technical support for following
                kind of issues:
              </p>
              <ul>
                <li>Server side scripting/programming errors/bugs</li>
                <li>Logical Bugs/Calculation related errors/bugs</li>
                <li>Connection errors/API Integration Errors</li>
                <p className="tcp-1">
                  Above support is not available if the errors/bugs arise due to
                  any external entity. Examples:
                </p>
                <li>
                  Server or software or application or extension downgrades or
                  upgrades
                </li>
                <li>
                  Edits done by any external entity/person/professional in the
                  code/scripts delivered by us.
                </li>
                <li>Operating System or Browser Version Downgrades/Upgrades</li>
                <li>
                  Any other factor which is not directly related to any
                  deficiency at the end of Impetrosys
                </li>
              </ul>
              <h5>Browser compatibility</h5>
              <p>
                Impetrosys IT Solutions makes every effort to design pages that
                work flawlessly on most popular current browsers i.e. IE11 and
                latest version of FireFox/Chrome released on the date of project
                agreement. We however cannot be held responsible for pages that
                do not display acceptably in new versions of browsers released
                after pages have been designed. IE10 and older IE versions are
                outdated browsers and hence we dont design with them in mind. We
                recommend using latest version of Chrome or Firefox instead of
                older versions of Microsoft Internet Explorer.
              </p>
              <h5>Examples of work:</h5>
              <p>
                Unless negotiated otherwise, Impetrosys IT Solutions retains the
                right to list/display the client name and logo with or without
                work performed (Design/Development/Online Promotion) for the
                Client in its respective portfolios and promotion materials.
                This over-rides all previous agreements and NDAs signed.
              </p>
              <h5>Share in profits from business or sale of business</h5>
              <p>
                After client makes the agreed payment for the project, Impetrosys
                will not claim share in clients profits from business or from
                sale of business to some other company.
              </p>
              <h5> Delayed Response From Client Side</h5>
              <p>
                Unreasonable delays from client side in providing the required
                feedback/information/data to finish the project shall exempt
                Impetrosys from meeting the timelines mentioned in the quote. In
                case client does not provide required details/data/information
                within 15 working days, client agrees that the payments made
                towards the project will be forfeited and retained by Impetrosys
                as full and final settlement against the work completed till
                date. Impetrosys shall not be liable to deliver any files to
                client if client is not traceable or not responsive for 15
                working days.
              </p>
              <h5>Quotations</h5>
              <p>
                The price quoted to the client is for the work agreed in the
                proposal document only. Should the client decide that changes
                are required after the project work has been initiated, then
                Impetrosys will provide a separate quote for the additional work
                and may need to review the timescale for completing the project.
                Cost estimates and prices quoted are valid for maximum one month
                unless otherwise agreed.
              </p>
              <h5>Content</h5>
              <p>
                After Impetrosys has delivered the website to client, client is
                solely responsible for the content/information/images posted on
                his website. If there is any error or omission by Impetrosys team
                while uploading/posting the content/information/images on
                clients website, Impetrosys will correct it if reported to
                Impetrosys representatives.
              </p>
              <h5>Mode of Payment</h5>
              <ul>
                <li>
                  Clients Based Outside India: International Wire Transfers,
                  Credit Card/PayPal via 2Checkout payment gateway.
                </li>
                <li>
                  Clients Based in India: NEFT, RTGS or Physical Cheques mailed
                  to our office address.
                </li>
              </ul>
              <h5>Payment Terms:</h5>
              <ul>
                <li>
                  Payment plan is agreed between the Client and Impetrosys based
                  on the milestones.
                </li>
                <li>
                  Payment shall be due within 14 days of the invoice date unless
                  specifically mentioned in the Invoice.
                </li>
                <li>
                  Full publication of the website/technical work will only take
                  place after full payment has been received in our account
                  unless otherwise agreed in writing.
                </li>
                <li>
                  Any material previously published may be removed if payment is
                  not received. When this occurs, a minimum charge of $250 USD
                  will be charged to have the site restored.
                </li>
                <li>
                  Accounts that have not been settled within 14 days of the date
                  shown on the invoice will incur a late payment charge of $100
                  USD or 5% of the Invoiced Amount (whichever is higher), for
                  each week delayed.
                </li>
                <li>
                  In case of delay in payments beyond the due date, Impetrosys
                  reserves the right to stop the work being commenced and Client
                  agrees to exempt Impetrosys from meeting the timelines agreed.
                </li>
              </ul>
              <h5>Note : </h5>
              <p>
                In no event, Impetrosys IT Solutions shall be liable to the client
                or any third party for any damages, including any lost profits,
                lost savings or other incidental, consequential or special
                damages arising out of the operation of or inability to operate
                these Web pages or website, even if Impetrosys IT Solutions has
                been advised of the possibility of such damages. Despite the
                best efforts of Impetrosys IT Solutions, errors in web page
                information may occur. At no time will Impetrosys IT Solutions be
                held responsible for accidentally including erroneous
                information, extending beyond correcting the error.
              </p>
              <p>
                Should Impetrosys IT Solutions waive any of these terms on an
                individual basis, this shall not affect the validity of
                remaining clauses or commit Impetrosys IT Solutions to waive the
                same clause on any other occasion.
              </p>
              <p>
                By agreeing to these terms and conditions, your statutory rights
                are not affected.
              </p>
              <p>
                Impetrosys IT Solutions reserves the right to change or modify any
                of these terms or conditions at any time, but agreements signed
                prior to the updates in this agreement remains unaffected.
                Please feel free to contact us for more info/clarification about
                any of the terms and conditions mentioned above.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Terms;
