import React from 'react';
import ContactUs from '../Components/Contacts/ContactUs';

const Contact = () => {
  return (
   <>
   <ContactUs/>
   </>
  );
};

export default Contact;
