import React from 'react';
import Product from "../Components/Products/Product";
import Navbar from "../Comman/Header/Navbar";
import Footer from '../Comman/Footers/Footer';


const Products = () => {
  return (
   <>
   <Navbar/>
   <Product/>
   <Footer/>
   </>
  );
};



export default Products;
