import React from 'react';
import Technology from "../Components/Technology/Technology";
import Navbar from "../Comman/Header/Navbar";
import Footer from '../Comman/Footers/Footer';


const Technologys = () => {
  return (
   <>
   <Navbar/>
   <Technology/>
   <Footer/>
   </>
  );
};



export default Technologys;
