import React from 'react';
import Service from "../Components/Services/Service";
import Navbar from "../Comman/Header/Navbar";
import Footer from '../Comman/Footers/Footer';


const Services = () => {
  return (
   <>
   <Navbar/>
   <Service/>
   <Footer/>
   </>
  );
};



export default Services;
