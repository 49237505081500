import React from "react";
import Navbar from "../Comman/Header/Navbar";
import Footer from "../Comman/Footers/Footer";
import { Container, Row, Col } from "react-bootstrap";

const Privacy = () => {
  return (
    <>
      <Navbar />

      <div className="privacy-section pt-120 pb-120">
        <Container>
          <Row>
            <Col md={12}>
              <h4 className="display-four text-center mt-5 mb-4 tcp-1">Privacy Policy</h4>
              <h5>What information do we collect?</h5>
              <p>
                We collect information from you when you fill out a form. When
                ordering or registering on our site, as appropriate, you may be
                asked to enter your: name, e-mail address, mailing address,
                phone number or credit card information. You may, however, visit
                our site anonymously.
              </p>
              <h5>
                Any of the information we collect from you may be used in one of
                the following ways:
              </h5>
              <ul>
                <li>
                  To personalize your experience Your information helps us to
                  better respond to your individual needs
                </li>
                <li>
                  To improve our website We continually strive to improve our
                  website offerings based on the information and feedback we
                  receive from you
                </li>
                <li>
                  To improve customer service Your information helps us to more
                  effectively respond to your customer service requests and
                  support needs
                </li>
                <li>
                  process transactions Your information, whether public or
                  private, will not be sold, exchanged, transferred, or given to
                  any other company for any reason whatsoever, without your
                  consent, other than for the express purpose of delivering the
                  purchased product or service requested.
                </li>
                <li>
                  To send periodic emails The email address you provide for
                  order processing, may be used to send you information and
                  updates pertaining to your order, in addition to receiving
                  occasional company news, updates,related product or service
                  information, etc.
                </li>
              </ul>
              <h5>How do we protect your information?</h5>
              <p>
                We implement a variety of security measures to maintain the
                safety of your personal information when you place an order or
                enter, submit, or access your personal information.
              </p>
              <h5>Do we use cookies?</h5>
              <p>
                Yes (Cookies are small files that a site or its service provider
                transfers to your computers hard drive through your Web browser
                (if you allow) that enables the sites or service providers
                systems to recognize your browser and capture and remember
                certain information We use cookies to understand and save your
                preferences for future visits and compile aggregate data about
                site traffic and site interaction so that we can offer better
                site experiences and tools in the future. We may contract with
                third-party service providers to assist us in better
                understanding our site visitors. These service providers are not
                permitted to use the information collected on our behalf except
                to help us conduct and improve our business.
              </p>
              <h5>Do we disclose any information to outside parties?</h5>
              <p>
                t sell, trade, or otherwise transfer to outside parties your
                personally identifiable information. This does not include
                trusted third parties who assist us in operating our website,
                conducting our business, or servicing you, so long as those
                parties agree to keep this information confidential. We may also
                release your information when we believe release is appropriate
                to comply with the law, enforce our site policies, or protect
                ours or others rights, property, or safety. However,
                non-personally identifiable visitor information may be provided
                to other parties for marketing, advertising, or other uses.
              </p>
              <h5>Third party links</h5>
              <p>
                Occasionally, at our discretion, we may include or offer third
                party products or services on our website. These third party
                sites have separate and independent privacy policies. We
                therefore have no responsibility or liability for the content
                and activities of these linked sites. Nonetheless, we seek to
                protect the integrity of our site and welcome any feedback about
                these sites.
              </p>
              <h5>Online Privacy Policy Only</h5>
              <p>
                This online privacy policy applies only to information collected
                through our website and not to information collected offline.
              </p>
              <h5>Changes to our Privacy Policy</h5>
              <p>
                If we decide to change our privacy policy, we will post those
                changes on this page.
              </p>
              <h5>Contacting Us</h5>
              <p>
                If there are any questions regarding this privacy policy you may
                contact us using the information below.
              </p>
              <ul>
                <li className="tcp-1">https://impetrosys.com/</li>
                <li className="tcp-1">
                  403-Fourth Floor, Fortune Ambience Business Space, South
                  Tukoganj, Indore, Madhya Pradesh 452001
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
