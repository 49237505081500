import React from "react";
import Navbar from "../Comman/Header/Navbar";
import Footer from "../Comman/Footers/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import calture from "../assets/images/calture.png";
const Faq = () => {
  return (
    <>
      <Navbar />

      <div className="faq-section pt-120 pb-120 ">
        <Container>
          <Row>
           
            <Col md={12}>
            <div className="faqs">
              <h4 className="display-four text-center mt-5 mb-4 tcp-1">Faq</h4>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How much experience do I need to apply for a specific
                    position?
                  </Accordion.Header>
                  <Accordion.Body>
                    Please click on the open positions on this page, based upon
                    your area of interest. You will land on a page where you can
                    view the minimum experience and qualification requirements.
                    We hire people with a wide range of professional experience
                    and educational background.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    What documents do I need to submit for applying online?
                  </Accordion.Header>
                  <Accordion.Body>
                    You only have to upload an up-to-date copy of your CV/resume
                    along with filling up the details asked in the online form.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How do I get notified that my job application has been
                    received by you?
                  </Accordion.Header>
                  <Accordion.Body>
                    You will receive a confirmation message and an email,
                    confirming that your application is received for a review.
                    If your candidate profile and application meet the
                    qualifications for a selected position, a recruiter will be
                    in contact with you at the earliest. If this takes long and
                    you want to inquire, drop an email with your details and
                    same CV again on info@impetrosys.com.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
            </Col>
            
            {/* <Col md={6}>
                <img src={calture} alt="calture" className="w-100"/>
            </Col> */}
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Faq;
