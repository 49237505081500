import React from 'react';
import Careers from "../Components/Careers/Careers";
import Navbar from "../Comman/Header/Navbar";
import Footer from '../Comman/Footers/Footer';


const Career = () => {
  return (
   <>
   <Navbar/>
   <Careers/>
   <Footer/>
   </>
  );
};



export default Career;
