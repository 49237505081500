import React from 'react';
import Aboutus from "../Components/Abouts/Aboutus";
import Navbar from "../Comman/Header/Navbar";
import Footer from '../Comman/Footers/Footer';


const About = () => {
  return (
   <>
   <Navbar/>
   <Aboutus/>
   <Footer/>
   </>
  );
};



export default About;
