import React from 'react';
import Calture from "../Components/CalturesAtImpetrosys/Calture";
import Navbar from "../Comman/Header/Navbar";
import Footer from '../Comman/Footers/Footer';


const Calture1 = () => {
  return (
   <>
   <Navbar/>
   <Calture/>
   <Footer/>
   </>
  );
};



export default Calture1;
