import React from "react";
import { useRoutes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Contact from "./Pages/Contact";
import Services from "./Pages/Services";
import About from "./Pages/About";
import Technology from "./Pages/Technology";
import Products from "./Pages/Products";
import Portfolio from "./Pages/Portfolio";
import Career from "./Pages/Career";
import Calture from "./Pages/Calture";
import Blog from "./Pages/Blog";
import Privacy from "./Pages/Privacy";
import Faq from "./Pages/Faq";
import Terms from "./Pages/Terms";
import Signup from "../src/Components/Contacts/Signup";
import Request from "./Components/Contacts/Request";
import Blogdetails from "../src/Components/Blogs/Blogdetails";
import Portfoliodetails from "../src/Components/Portfolios/Portfoliodetails";
export default function Router() {
  // <HomePage />
  return useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/contact", element: <Contact /> },
    { path: "/services", element: <Services /> },
    { path: "/about", element: <About /> },
    { path: "/technology", element: <Technology /> },
    { path: "/hire-experts", element: <Signup /> },
    { path: "/request-a-quote", element: <Request /> },
    { path: "/products", element: <Products /> },
    { path: "/portfolio", element: <Portfolio /> },
    { path: "/career", element: <Career /> },
    { path: "/culture", element: <Calture /> },
    { path: "/blog", element: <Blog /> },
    { path: "/blog-details", element: <Blogdetails /> },
    { path: "/portfolio-details", element: <Portfoliodetails /> },
    { path: "/privacy-policy", element: <Privacy /> },
    { path: "/terms-and-condition", element: <Terms /> },
    { path: "/faq", element: <Faq /> },
  ]);
}
