import React, { useState, useEffect } from "react";
import "./Footer.css"; // Assuming you will create a separate CSS file for styles
import Container from "react-bootstrap/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Col from "react-bootstrap/Col";
import FB from "../../assets/images/FB.png";
import twitter from "../../assets/images/twitter.png";
import linkedIn from "../../assets/images/linkedIn.png";
import insta from "../../assets/images/insta.png";
import google from "../../assets/images/google.png";
import logo from "../../assets/images/logo.png";
import shape from "../../assets/images/shape.png";
import { Link } from "react-router-dom";
import { BiPhoneCall } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { BiMap } from "react-icons/bi";
import { CiLocationArrow1 } from "react-icons/ci";
import { NavLink } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
const Footer = () => {
  //loder
  const { promiseInProgress } = usePromiseTracker();
  const [footerdata, setFooterData] = useState({})

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getwebsitesetting`)
      .then((response) => {
        if (response.data.status_code == 200) {
          setFooterData(response.data.websitesettings)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showAlert = (message) => {
    Swal.fire({
      title: "Message Sent",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("email", values.email);
    const url = `${process.env.REACT_APP_BASE_URL}/subscribenewsletter`
    trackPromise(
      axios
        .post(url, bodyFormData, {
          "Content-Type": "multipart/form-data",
          headers: { Accept: "application/json" },
        })
        .then((response) => {
          if (response.data.status_code === 200) {
            showAlert(response.data.message)
          }
          else if (response.data.status_code === 201) {
            showToastMessageone(response.data.message.email);
          }
        })
        .catch((error) => {
          console.log(error)
        })
    )
  }

  // useformik function
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });


  return (
    <>
      <section
        className="news-letter-section-02 texture-bg pb-120"
        id="newsletter-02">
        <div className="top-wave-shape mt-n1">
          <img className="w-100" src={shape} alt="wave shape" />
        </div>
        <div className="container">
          <div className="row g-sm-6 g-2 pt-120">
            <div className="col-lg-8">
              <h3 className="display-four">
                "Stay Updated with Our Newsletter"
              </h3>
            </div>
            <div className="col-lg-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="input-area alt">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Type your email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={formik.handleChange} />
                  <div className="flex justify-center items-center mt-1">
                    {promiseInProgress ? (
                      <div className="spinner flex justify-center items-center h-12">
                        <ThreeDots
                          height="50"
                          width="50"
                          color="#ffb430"
                          ariaLabel="circles-loading"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <button className="icon-btn">
                        <span className="icon alt-size fs-xl fw-bold">
                          <i>
                            <CiLocationArrow1 />
                          </i>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                {formik.errors.email && formik.touched.email && (
                  <p className="errormessage">{formik.errors.email}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-section texture-bg">
        <Container>
          {/* Footer menu */}
          <Row data-aos="fade-up" data-aos-duration="2000">
            <Col lg={3} sm={6}>
              <div className="footer-logo mb-lg-6 mb-4">
                <NavLink to="/" className="logo">
                  <img src={logo} alt="logo" />
                </NavLink>
              </div>
              <p className="description mb-lg-8 mb-sm-6 mb-4">
                {footerdata.footer_text}
              </p>
            </Col>
            <Col lg={3} sm={6}>
              <div className="footer-widget mt-5">
                <h4 className="widget-title">Quick Links</h4>
                <ul className="footer-menu d-grid gap-3">
                  <li><Link to="/about">About IMPETROSYS</Link></li>
                  <li><Link to="/career">Carrer @IMPETROSYS</Link></li>
                  <li><Link to="/contact">Get in Touch</Link></li>
                  <li><Link to="/blog">Latest Blogs</Link></li>
                </ul>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className="footer-widget mt-5">
                <h4 className="widget-title">Contact Information</h4>
                <ul className="footer-menu d-grid gap-3">
                  <li>
                    <a
                      href="tel:+91 731 358 9090"
                      className="d-flex align-items-center gap-3 alt" >
                      <i><BiPhoneCall /></i>
                      {footerdata.contact_no}
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:info@example@gmail.com"
                      className="d-flex align-items-center gap-3 alt" >
                      <i><FiMail /></i>
                      {footerdata.email}
                    </a>
                  </li>
                  <li>
                    <a className="d-flex align-items-center gap-3 alt">
                      <i><BiMap /></i>
                      {footerdata.s_address}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className="footer-widget mt-5">
                <h4 className="widget-title">Follow us</h4>
                <p className="mb-lg-6 mb-4">
                  Don’t miss out—follow us today and stay updated with the
                  latest excitement directly in your feed!
                </p>
                <ul className="d-flex align-items-center justify-content-center justify-content-lg-start gap-2">
                  <li>
                    <a href={footerdata.facebook_url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={FB}
                        alt="facebook icon"
                        className="footer-img" />
                    </a>
                  </li>
                  <li>
                    <a href={footerdata.twitter_url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={twitter}
                        alt="twitter icon"
                        className="footer-img" />
                    </a>
                  </li>
                  <li>
                    <a href={footerdata.linkedin_url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={linkedIn}
                        alt="linkedin icon"
                        className="footer-img" />
                    </a>
                  </li>
                  <li>
                    <a href={footerdata.instagram_url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={insta}
                        alt="instagram icon"
                        className="footer-img" />
                    </a>
                  </li>
                  <li>
                    <a href="https://g.co/kgs/89gVGro" target="_blank" rel="noopener noreferrer">
                      <img
                        src={google}
                        alt="google icon"
                        className="footer-img"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          {/* Footer bottom */}
          <div className="footer-bottom d-between flex-wrap-reverse ">
            <span>
              Copyright © {new Date().getFullYear()}
              <a href="#" className="tcp-1 link-text-2">
              &nbsp; IMPETROSYS &nbsp;
              </a>
              All Rights Reserved.
            </span>
            <div className="footer-widget">
              <ul className="footer-menu d-flex gap-4">
                <li>
                  <Link to="/terms-and-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </footer>
      <ScrollToTop
        smooth
        color="#000"
        style={{ background: "#1ee579", bottom: "10px", right: "8px" }}
      />
    </>
  );
};

export default Footer;
