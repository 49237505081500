import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/autoplay";
import "./Lifecycle.css";
import { TbPointFilled } from "react-icons/tb";
import { IoStar } from "react-icons/io5";
import { IoStarHalf } from "react-icons/io5";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import quote0 from "../../../assets/images/quote0.png";
import quote1 from "../../../assets/images/quote1.png";
import thumb2 from "../../../assets/images/profile.png";
// import thumb5 from "../../../assets/images/thumb5.png";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";

const Clients = () => {
  //loder
  const { promiseInProgress } = usePromiseTracker();

  // state
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [testimonialsdata, settestimonialsdata] = useState([])

  // api calling gettestimonials
  useEffect(() => {
    trackPromise(
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/gettestimonials`)
        .then((response) => {
          if (response.status == 200) {
            settestimonialsdata(response.data.testimoniallist)
          }
        })
        .catch((error) => {
          console.log(error);
        })
    )
  }, [])

  return (
    <section className="testimonial-section-04 texture-bg pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center mb-lg-10 mb-sm-8 mb-6">
          <div className="col-lg-6 text-center" data-aos="fade-up">
            <span className="subheading-border fw-medium mb-4 fs-xl">
              <span className="fs-2xl">
                <i>
                  <TbPointFilled />
                </i>
              </span>
              Testimonial
            </span>
            <h4 className="display-four pe-xxl-2">
              Success Stories from Our Trusted Clients
            </h4>
          </div>
        </div>
        {/* Testimonial Slider */}
        <div className="row g-6 justify-content-lg-between justify-content-center align-items-center">
          <div className="col-lg-1 d-none d-lg-block">
            <div className="swiper-btns" data-aos="fade-up">
              <div
                className="features-prev button-prev fs-lg"
                tabIndex="0"
                role="button"
                aria-label="Previous slide"
              >
                <i>
                  <MdKeyboardArrowLeft />
                </i>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-lg-8" data-aos="fade-up">
            <Swiper
              modules={[Navigation, Thumbs,
                // Autoplay
              ]}
              spaceBetween={30}
              navigation={{
                nextEl: ".features-next",
                prevEl: ".features-prev",
              }}
              thumbs={{ swiper: thumbsSwiper }}
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              className="testimonial-swiper-4 mb-8"
            >
              <div className="loader-box mt-3">
                {promiseInProgress === true ? (
                  <div
                    className="spinner"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "300px",
                    }}
                  >
                    <ThreeDots
                      height="70"
                      width="70"
                      color="#063014"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <>
                    {
                      testimonialsdata.map((ele, index) => {
                        return (
                          <div key={index}>
                            <SwiperSlide>
                              <div className="text-center">
                                <div className="mb-lg-6 mb-3">
                                  <h4 className="mb-2 tcp-1">{ele.name}</h4>
                                  <span className="fw-normal">{ele.post}</span>
                                </div>
                                <ul className="rating-list d-flex gap-2 gap-lg-1 justify-content-center tcp-1 mb-lg-4 mb-2">
                                  <li>
                                    <i>
                                      <IoStar />
                                    </i>
                                  </li>
                                  <li>
                                    <i>
                                      <IoStar />
                                    </i>
                                  </li>
                                  <li>
                                    <i>
                                      <IoStar />
                                    </i>
                                  </li>
                                  <li>
                                    <i>
                                      <IoStar />
                                    </i>
                                  </li>
                                  <li>
                                    <i>
                                      <IoStarHalf />
                                    </i>
                                  </li>
                                </ul>
                                <div className="testimonial-quote d-flex">
                                  <div className="quote-icon mb-auto mt-n6">
                                    <img className="w-100" src={quote0} alt="quote icon" />
                                  </div>
                                  <p className="fs-xl testimon-pra">
                                    {/* I am really impressed by the quality of services I
                      received from IMPETROSYS. You were right on schedule,
                      charged reasonable prices, were professional and courteous
                      in dealings, and delivered items well before time. I have
                      got a good SoundChatRadio Website and Mobile Apps for
                      myRadio Station. */}
                                    {ele.comments}
                                  </p>
                                  <div className="quote-icon mt-auto mb-n3">
                                    <img className="w-100" src={quote1} alt="quote icon" />
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          </div>
                        )
                      })
                    }
                  </>
                )}
              </div>


              {/* <SwiperSlide>
                <div className="text-center">
                  <div className="mb-lg-6 mb-3">
                    <h4 className="mb-2 tcp-1">Sheldon B.</h4>
                    <span className="fw-normal">General Manager</span>
                  </div>
                  <ul className="rating-list d-flex gap-2 gap-lg-1 justify-content-center tcp-1 mb-lg-4 mb-2">
                    <li>
                      <i>
                        <IoStar />
                      </i>
                    </li>
                    <li>
                      <i>
                        <IoStar />
                      </i>
                    </li>
                    <li>
                      <i>
                        <IoStar />
                      </i>
                    </li>
                    <li>
                      <i>
                        <IoStar />
                      </i>
                    </li>
                    <li>
                      <i>
                        <IoStarHalf />
                      </i>
                    </li>
                  </ul>
                  <div className="testimonial-quote d-flex">
                    <div className="quote-icon mb-auto mt-n6">
                      <img className="w-100" src={quote0} alt="quote icon" />
                    </div>
                    <p className="fs-xl">
                      IMPETROSYS is the firm to work with if you want to keep up
                      to high standards. The professional workflows they stick
                      to result in exceptional quality.
                    </p>
                    <div className="quote-icon mt-auto mb-n3">
                      <img className="w-100" src={quote1} alt="quote icon" />
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}

            </Swiper>
            <div className="clients-sp">
              <Swiper
                modules={[Thumbs]}
                spaceBetween={10}
                slidesPerView={2}
                onSwiper={setThumbsSwiper}
                className="ts-swiper-4-thumb"
              >
                <SwiperSlide>
                  <div className="ts-thumb">
                    <img className="w-100" src={thumb2} alt="thumb" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="ts-thumb">
                    <img className="w-100" src={thumb2} alt="thumb" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-lg-1 d-none d-lg-block">
            <div className="swiper-btns">
              <div
                className="features-next button-next fs-lg"
                tabIndex="0"
                role="button"
                aria-label="Next slide"
              >
                <i>
                  <MdKeyboardArrowRight />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
