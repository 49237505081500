// Comman/Footers/Footer.js
import React from 'react';
import Navbar from '../Comman/Header/Navbar';
import Footer from '../Comman/Footers/Footer';
import Homeslider from "../Components/Home/Slider/Homeslider";
import Lifecycle from "../Components/Home/Lifecycles/Lifecycle";
import Whychooseus from "../Components/Home/Lifecycles/Whychooseus";
import Projects from "../Components/Home/Lifecycles/Projects";
import ExpertsProfile from "../Components/Home/Lifecycles/ExpertsProfile";
import Clients from "../Components/Home/Lifecycles/Clients";
import ContactNews from "../Components/Home/Lifecycles/ContactNews";


const HomePage = () => {
  return (
    <>
      <Navbar />
      <Homeslider />
      <Lifecycle />
      <Whychooseus />
      <Projects />
      <ExpertsProfile />
      <Clients />
      <ContactNews />
      <Footer />
    </>
  );
};

export default HomePage;
